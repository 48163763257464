.column {
  /* border: 1px black solid; */
}
* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  touch-action: manipulation; /* to avoid double-tapping */
}
.my-border {
  /* border: 2px solid black; */
}
html {
  font-size: 16px;
}
body {
  /* font-family: Helvetica, Arial, sans-serif; */
  font-size: 16px;
  font-family: "Open Sans";
  height: 100vh;
  overflow: auto;
}
form textarea,
text {
  resize: none;
}
.container-home-page {
  margin-top: 20px;
}
.container-admin-home-page {
  margin-top: 80px;
}
.container-meriyaadi-logo {
  position: relative;
}
.container-product-image {
  /* position: relative; */
  /* height:120px; */
}
.container-footer {
  /* margin-top: 250px; */
}
.div-clickable {
  cursor: pointer;
}
.div-product-image {
  /* height: 66px; */
  /* width: 100px; */
  height: 100px;
  width: 150px;
}
.div-masterlist-image {
  height: 67px;
  width: 100px;
}
/* for smaller screen, discount should be placed, closer */
.discount-amount {
  position: absolute;
  top: 0%;
  left: 0%;
  background-color: red;
  color: white;
  padding: 3px;
  padding-bottom: 0px;
}
.quick-product-discount {
  color: red;
}
.table {
  font-size: 0.9rem;
}
.yaadi-content {
  /* font-size: 1.3em; */
  font-weight: bolder;
  text-decoration: none;
}
.container-a-products {
  margin-top: 120px;
}
.container-a-quick-products {
  margin-top: 120px;
  margin-bottom: 120px;
}
.container-a-product {
  font-size: 1.1rem;
  /* 06.03.2025 */
}
.container-form-start {
  margin-top: 50px;
}
.product-border {
  margin-bottom: 8px;
}
.container-search-list {
  margin-top: 70px;
}
.container-category-list {
  margin-top: 30px;
}
.quantity-change-button {
  border-radius: 20px;
}
.homepage-logo {
  margin-bottom: 50px;
}
.div-shop-logo {
  width: 120px;
  height: 80px;
  object-fit: contain;
}
.div-aboutus-image {
  width: 200px;
  height: 150px;
  /* object-fit: contain; */
  /* border: 2px solid red; */
}
.repeat-order-info {
  /* border: 2px solid black; */
}
.homepage-image {
  margin-bottom: 20px;
}
.masterlist-image {
  width: 60%;
}
.homepage-button {
  margin-bottom: 20px;
}
.homepage-shop-details {
  border-bottom: 2px solid grey;
}
.homepage-contact-details,
.customer-form-contact-details {
  /* background-color: gray; */
}

.div-nav-bar {
  border-bottom: 2px solid red;
}

.search-icon {
  padding-right: -10px;
}
.container-final-yaadi {
  margin-bottom: 50px;
}
.container-final-yaadi-whatsapp {
  margin-top: 180px;
}
.container-login-form {
  margin-top: 150px;
}
.container-body {
  display: flex;
  flex-direction: column;
}
.container-make-yaadi {
  /* height: 15%; */
}
.make-yaadi-quantity input {
  /* border: 1px solid white; */
  /* width: 90%; */
}
.search-by-category a {
  text-decoration: none;
}
.search-by-category a:hover {
  text-decoration: none;
}
.search-by-brand a {
  text-decoration: none;
}
.search-by-brand a:hover {
  text-decoration: none;
}
/*-----------------autocomplete----------------*/
/*------ downloaded here :https://blog.bitsrc.io/building-a-react-autocomplete-component-from-scratch-b78105324f4c */
.search-box {
  border: 1px solid black;
  border-radius: 2px;
  /* font-size: 2rem; */
  width: 100%;
  padding: 6px;
  transition: width 0.3s;
}
.search-box:focus {
  width: 100%;
  outline: none;
  border: 1px solid #08a1b6;
}
.search-btn {
  height: 100%;
  /* width: 4em; */
  margin-top: -2em;
  position: absolute;
  top: 50%;
  right: 0.5em;

  opacity: 0.2;
  background-color: transparent;
  border: 0;
  background-repeat: no-repeat;
  background-size: 100%;
}

.search-btn:hover {
  outline: none;
  opacity: 0.4;
  cursor: pointer;
}
.search-btn:focus {
  outline: none;
  opacity: 0.6;
}
.autocomplete-options {
  display: block;
  list-style: none;
  width: 30em;
  transition: width 0.3s;
  margin: auto;
  position: relative;
}

.autocomplete-options div {
  display: block;
  background: white;
  margin: 10px auto;
  padding: 10px;
  font-size: 1.2rem;
  width: 100%;
  border-radius: 2px;
}
.autocomplete-options div:hover {
  font-weight: bold;
  color: #00b4cc;
  cursor: pointer;
  transition: 0.3s all;
}
.autocomplete-options div.option-active {
  background: whitesmoke;
  font-size: 1.2rem;
  color: blue;
}
.no-options {
  color: white;
}
/*-----------------autocomplete----------------*/
.carousel-control-next-icon,
.carousel-control-prev-icon {
  filter: invert(1);
  background-image: none;
  background-color: blue;
}
.carousel-indicators {
  filter: invert(1);
  background-image: none;
  margin: 0px;
}
.container-about-us {
  margin-top: 80px;
  /* padding-bottom: 100px; */
}
.about-us-address {
  line-height: 2;
}
.image-slider {
  /* margin-top: 100px; */
}
.color-lightBlue {
  background-color: lightblue;
}
.color-lightSkyBlue {
  background-color: lightskyblue;
}
.color-lightYellow {
  background-color: lightgoldenrodyellow;
}
.form-heading {
  text-align: center;
  font-size: 1.2rem;
  /* 06.03.2025 */
  text-transform: uppercase;
}

.div-search,
.div-drop-down-category,
.div-drop-down-brand {
  margin-top: 3px;
}
.nav-bar img {
  height: 80%;
}
button:disabled {
  cursor: not-allowed;
  pointer-events: all !important;
}
.promo-container {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  background-color: yellow;
}
.promo-container img {
  max-width: 100%;
  height: 100%;
}
.product-window {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.category-container {
  display: flex;
  flex-direction: column;
  background-color: red;
  margin-top: 10px;
  margin-left: 10px;
  width: 12%;
}
.category-container button {
  background-color: white;
  color: red;
  padding: 5px 10px;
  margin: 10px;
}
.container-product {
  margin-top: 200px;
}
.container-yaadi {
  margin-top: 120px;
}
.container-repeat-order {
  margin-top: 30px;
}
.container-cart-product {
  border-bottom: 1px gray solid;
}
.div-cart-product {
  font-size: 0.9rem;
  /* 06.03.2025 */
}

.div-image-preview {
  height: 100px;
  width: 140px;
  border: 2px solid red;
  margin: 5px auto;
}
.heading-saved-products {
  margin-top: 50px;
}
.text-header-added-product {
  text-align: center;
  font-weight: bolder;
}
.carted-product {
  border: 0px solid green;
}
.btn-add-product {
  /* width: 60%; */
}
.button-add-cart {
  display: flex;
  margin: 0px auto;
}
.text-thick {
  font-weight: bold;
}
.text-italic {
  font-style: italic;
}
.shopping-cart {
  background-image: url("/images/meriyaadi_logo.jpg");
}
.smaller-text {
  font-size: 0.9rem;
}
.small-text {
  font-size: 0.8rem;
}

.myborder-top {
  border-top: 2px solid grey;
}
.bigger-text {
  font-size: 1.1rem;
}
.sdc-bigger-text {
  font-size: 1.3rem;
}

.striked-text {
  text-decoration: line-through;
}
.error-text {
  color: red;
  font-size: 0.9rem;
}
.text-red {
  color: red;
}

.text-black {
  color: black;
}
.text-white {
  color: white;
}
.bg-white {
  background-color: white;
}

.text-green {
  color: green;
}
.thick-red-text {
  color: red;
  font-weight: bold;
}
.my-btn {
  padding: 10px;
  border: none;
}
/*--------------From w3schools ----------------*/
/* The Modal (background) */
.modal {
  /* display: none; Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}
/* Modal Content */
.modal-content {
  position: relative;
  background-color: #fefefe;
  margin: auto;
  padding: 0;
  border: 1px solid #888;
  width: 80%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;
}
/* Add Animation */
@-webkit-keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

/* The Close Button */
.close {
  color: white;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.modal-header {
  padding: 2px 16px;
  background-color: #5cb85c;
  color: white;
}

.modal-body {
  padding: 2px 16px;
}

.modal-footer {
  padding: 2px 16px;
  background-color: #5cb85c;
  color: white;
}
/*--------------End of Modal ----------------*/
/*--------------Customized color brown ----------------*/
.btn-mybrown {
  padding: 5px 10px;
  border: none;
  background-color: rgb(182, 122, 66);
  color: black;
}
.btn-mybrown-dark {
  padding: 5px 10px;
  border: none;
  background-color: rgb(145, 81, 23);
  color: black;
}
.bg-mybrown {
  background-color: rgb(182, 122, 66);
  color: black;
}
.bg-mybrown-dark {
  background-color: rgb(145, 81, 23);
  color: black;
}
.mybrown-dark {
  color: rgb(145, 81, 23);
}
.mybrown {
  background-color: rgb(182, 122, 66);
}
.text-mybrown {
  background-color: rgb(182, 122, 66);
}
.border-mybrown {
  border-bottom: 1px solid rgb(145, 81, 23);
}
.border-top-mybrown {
  border-top: 1px solid rgb(145, 81, 23);
}
.border-all-mybrown {
  border: 1px solid rgb(145, 81, 23);
}
/*--------------Customized color green ----------------*/
.btn-mygreen {
  padding: 5px 10px;
  border: none;
  background-color: rgb(153 229 153);
  color: black;
}
.btn-mygreen-dark {
  padding: 5px 10px;
  border: none;
  background-color: rgb(24, 151, 24);
  color: white;
}
.bg-mygreen {
  background-color: rgb(153 229 153);
  color: black;
}
.bg-mygreen-dark {
  background-color: rgb(24, 151, 24);
  color: black;
}
.mygreen {
  color: rgb(153 229 153);
}
.mygreen-dark {
  color: rgb(24, 151, 24);
}
.text-mygreen {
  color: rgb(24, 151, 24);
}
.border-mygreen {
  border-bottom: 1px solid rgb(24, 151, 24);
}
.border-top-mygreen {
  border-top: 1px solid rgb(24, 151, 24);
}
.border-all-mygreen {
  border: 1px solid rgb(24, 151, 24);
}
/*--------------Customized color cyan ----------------*/
.btn-mycyan {
  padding: 5px 10px;
  border: none;
  background-color: rgb(175, 238, 238);
  color: black;
}
.btn-mycyan-dark {
  padding: 5px 10px;
  border: none;
  background-color: rgb(0, 255, 255);
  color: white;
}
.bg-mycyan {
  background-color: rgb(175, 238, 238);
  color: black;
}
.bg-mycyan-dark {
  background-color: rgb(0, 255, 255);
  color: black;
}
.mycyan {
  color: rgb(175, 238, 238);
}
.mycyan-dark {
  color: rgb(0, 255, 255);
}
.text-mycyan {
  color: rgb(175, 238, 238);
}
.border-mycyan {
  border-bottom: 1px solid rgb(0, 255, 255);
}
.border-top-mycyan {
  border-top: 1px solid rgb(0, 255, 255);
}
.border-all-mycyan {
  border: 1px solid rgb(0, 255, 255);
}
/*--------------Customized color purple ----------------*/
.btn-mypurple {
  padding: 5px 10px;
  border: none;
  background-color: rgb(173, 90, 173);
  color: black;
}
.btn-mypurple-dark {
  padding: 5px 10px;
  border: none;
  background-color: rgb(128, 0, 128);
  color: white;
}
.bg-mypurple {
  background-color: rgb(173, 90, 173);
  color: black;
}
.bg-mypurple-dark {
  background-color: rgb(128, 0, 128);
  color: black;
}
.mypurple {
  color: rgb(173, 90, 173);
}
.mypurpl-dark {
  color: rgb(128, 0, 128);
}
.text-mypurple {
  color: rgb(151, 69, 120);
}
.border-mypurple {
  border-bottom: 1px solid rgb(128, 0, 128);
}
.border-top-mypurple {
  border-top: 1px solid rgb(128, 0, 128);
}

.border-all-mypurple {
  border: 1px solid rgb(128, 0, 128);
}
/*--------------Customized color blue ----------------*/
.btn-myblue {
  padding: 5px 10px;
  border: none;
  background-color: rgb(114, 139, 145);
  color: black;
}
.btn-myblue-dark {
  padding: 5px 10px;
  border: none;
  background-color: rgb(38, 96, 110);
  color: white;
}
.bg-myblue {
  background-color: rgb(114, 139, 145);
  color: black;
}
.bg-myblue-dark {
  background-color: rgb(38, 96, 110);
  color: black;
}
.myblue {
  color: rgb(114, 139, 145);
}
.myblue-dark {
  color: rgb(38, 96, 110);
}
.text-myblue {
  color: rgb(114, 139, 145);
}
.border-myblue {
  border-bottom: 1px solid rgb(38, 96, 110);
}
.border-top-myblue {
  border-top: 1px solid rgb(38, 96, 110);
}
.border-all-myblue {
  border: 1px solid rgb(38, 96, 110);
}

/* ------------------Yellow-------------------------- */
.btn-myyellow {
  padding: 5px 10px;
  border: none;
  background-color: rgb(250 205 132);
  color: black;
}
.btn-myyellow-dark {
  padding: 5px 10px;
  border: none;
  background-color: rgb(230, 165, 0);
  color: white;
}
.bg-myyellow {
  background-color: rgb(250 205 132);
  color: black;
}
.bg-myyellow-dark {
  background-color: rgb(230, 165, 0);
  color: black;
}
.myyellow {
  color: rgb(250 205 132);
}
.myyellow-dark {
  color: rgb(230, 165, 0);
}
.text-myyellow {
  color: rgb(230, 165, 0);
}
.border-myyellow {
  border-bottom: 1px solid rgb(230, 165, 0);
}
.border-top-myyellow {
  border-top: 1px solid rgb(230, 165, 0);
}
.border-all-myyellow {
  border: 1px solid rgb(230, 165, 0);
}
/* ---------------------- */
.btn-panel {
  font-size: 1.1rem;
  /* larger; */
}
.btn-mygrey {
  border: none;
  background-color: gray;
}
.text-myblack {
  color: black;
}
.text-mygrey {
  color: gray;
}
.bg-mygrey {
  background-color: grey;
}
.bg-myblack {
  background-color: black;
}
.text-mywhite {
  color: white;
}

.bg-myblue {
  /* background-color: #4da6ff; */
}
.bg-myred {
  background-color: #fa6464;
}
.mybtn {
  padding: 8px;
  border: none;
}
.mybtn:active {
  opacity: 0.5;
}
.mybtn:hover {
  border: 1px solid black;
}
.home-page-borders {
  border-top: 2px solid rgb(255, 165, 0);
  /* border-bottom: 2px solid rgb(255, 165, 0); */
}
.home-page-blue-color {
  color: rgb(0, 23, 66);
}
.container-product .product-name,
.product-weight {
  color: black;
}
.product-qty {
  display: flex;
}
.info-selected-category {
  color: blue;
  text-align: left;
  line-height: 2;
}
.show-image {
  align-self: flex-end;
}

pre {
  max-width: 100%;
  overflow: auto;
}
/* 2023 -- below one is very important */
.div-center {
  display: inline-block;
  text-align: justify;
}
.about-us-text,
.whatsApp-button-info,
.faq,
.payment-info {
  white-space: pre-line;
  line-height: 125%;
  font-size: 1.2rem;
  text-align: justify;
}
.tips {
  white-space: pre-line;
  line-height: 125%;
  text-align: justify;
}
.admin-icons {
  color: grey;
  padding: 4px;
  margin: 0x 10px;
  border: none;
  display: inline-block;
}
a {
  /* color: black; */
  /* text-decoration: none; */
}
a:hover {
  text-decoration: none;
}
.container-list-table {
  margin-top: 150px;
  padding-bottom: 300px;
}
.star-radio-buttons {
  display: none;
}
.star-radio-buttons {
  cursor: pointer;
  transition: color 200ms;
}
.form-customer-entry {
  /* margin-bottom: 100px; */
}
/* smaller screens (upto 576px)*/
@media screen and (max-width: 768px) {
  html {
    font-size: 13px;
  }
  .discount-amount {
    left: 16%;
  }
  .div-logo {
    order: 1;
  }
  .div-promo2 {
    order: 2;
  }
  .div-toggler-button {
    order: 4;
  }
  .div-shopping-cart {
    order: 3;
  }
  .normal-text {
    display: none;
  }
  .short-text {
    display: inline-block;
  }
  .homepage-contact-details {
  }
  .container-navbar {
    padding-top: 5px;
  }
  .customer-form-shop-name {
    /* display: none; */
  }
  .container-add-form {
    margin-top: 100px;
  }
}
/* Larger screens (more than 768.1px)*/
@media screen and (min-width: 768.1px) {
  html {
    font-size: 16px;
  }
  .div-logo {
    order: 1;
  }
  .div-promo1 {
    order: 2;
  }
  .div-toggler-button {
    order: 3;
  }
  .div-shopping-cart {
    order: 4;
  }
  .normal-text {
    display: inline-block;
  }
  .short-text {
    display: none;
  }
  .div-shopping-cart .btn {
    font-size: 1.2rem;
  }
  .card-special-offer {
    border-bottom: 2px solid red;
  }
  .div-shopping-cart .cart-count,
  .cart-amount {
    font-size: 1.5em;
  }
  .customer-form-contact-details {
    position: fixed;
    bottom: 0;
  }
  .form-customer-entry {
    padding-left: 150px;
    padding-right: 150px;
  }
  .form-login {
    padding-left: 150px;
    padding-right: 150px;
  }
  .container-add-form {
    margin-top: 100px;
  }

  .nav-bar-logo-row {
    padding-left: 100px;
    padding-right: 100px;
  }
  .container-quick-product {
    /* border-right : 1px solid gray  */
  }
  .container-a-products {
    margin-top: 150px;
  }
  .container-a-quick-products {
    margin-top: 110px;
  }
}
/* ///Added on 06.03.2025//// */
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 576px) {
  .mycontainer {
    width: 95%;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 576px) {
  .mycontainer {
    width: 90%;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .mycontainer {
    width: 80%;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .mycontainer {
    width: 70%;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .mycontainer {
    width: 65%;
  }
}
